import React from "react";

import { RouterProvider, createBrowserRouter } from "react-router-dom";

// Pages
import Dashboard from "../pages/Dashboard";
import Payment from "../pages/Payment";
import AppRedirect from "../pages/AppRedirect";
import Reps from "../pages/Reps";
import PaymentSuccess from "../pages/PaymentConfirmation/success";
import PaymentFail from "../pages/PaymentConfirmation/fail";
import ErrorPage from "../pages/404";

// Modals
import InfoModal from "../components/Modals/InfoModal";
import ErrorModal from "../components/Modals/ErrorModal";

const Router = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Dashboard />,
    },
    {
      path: "payment",
      element: <Payment />,
    },
    {
      path: "success",
      element: <PaymentSuccess />,
    },
    {
      path: "fail",
      element: <PaymentFail />,
    },
    {
      path: "/app-redirect",
      element: <AppRedirect />,
    },
    {
      path: "/reps/:firstName/:lastName/:phone/:email/:companyId/:imgPathname",
      element: <Reps />,
    },
    {
      path: "*",
      element: <ErrorPage />,
    },
  ]);

  return (
    <>
      <RouterProvider router={router} />
      <InfoModal />
      <ErrorModal />
    </>
  );
};

export default Router;
