import React, { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";

const frequencies = [
  { value: "company", label: "Company", priceSuffix: "/month" },
  { value: "individual", label: "Individual", priceSuffix: "/month" },
];
const tiers = [
  {
    name: "Knockpro Lite",
    id: "tier-normal",
    href: "mailto:support@knockproapp.com?subject=Book a Demo&body=Hi Knockpro Team, I would like to book a demo for Knockpro Lite.",
    price: { company: "$29.99", individual: "Free" },
    description: "The essentials to provide your best work for clients.",
    subtitle: "1 user minimum",
    features: ["Canvassing"],
    mostPopular: false,
  },
  {
    name: "Knockpro Premium",
    id: "tier-premium",
    href: "mailto:support@knockproapp.com?subject=Book a Demo&body=Hi Knockpro Team, I would like to book a demo for Knockpro Premium.",
    price: { company: "$39.99", individual: "$19.99" },
    description: "A plan that enables effective communication.",
    subtitle: "10 users minimum",
    features: [
      "KPIs",
      "Canvassing",
      "Company Onboarding/Rep Docs",
      "Scheduling",
    ],
    "features-individual": ["Canvassing", "Scheduling"],
    mostPopular: true,
  },
  {
    name: "Knockpro Elite",
    id: "tier-Platinum",
    href: "mailto:support@knockproapp.com?subject=Book a Demo&body=Hi Knockpro Team, I would like to book a demo for Knockpro Elite.",
    price: { company: "$49.99" },
    description: "A plan that enables users to scale faster",
    subtitle: "50 users minimum",
    features: [
      "Teams",
      "Canvassing",
      "Company Onboarding/Rep Docs",
      "Scheduling",
      "Training Academy",
      "Messaging",
      "Pipeline Management",
    ],
    mostPopular: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Pricing = () => {
  const [frequency, setFrequency] = useState(frequencies[0]);

  return (
    <div className="bg-white py-24 sm:py-32" id="pricing">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">
            Pricing
          </h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Pricing plans for Companies and Individuals
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
          Choose an affordable plan that’s packed with the best features for
          engaging your audience, creating customer loyalty, and driving sales.
        </p>
        <div className="mt-16 flex justify-center">
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
          >
            <RadioGroup.Label className="sr-only">
              Payment frequency
            </RadioGroup.Label>
            {frequencies.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) =>
                  classNames(
                    checked ? "bg-indigo-600 text-white" : "text-gray-500",
                    "cursor-pointer rounded-full px-2.5 py-1"
                  )
                }
              >
                <span>{option.label}</span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {tiers.map((tier) => {
            if (!tier.price[frequency.value]) {
              return null;
            }
            const featureToShow = tier["features-individual"]
              ? frequency.value === "individual"
                ? tier["features-individual"]
                : tier["features"]
              : tier["features"];
            return (
              <div
                key={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? "ring-2 ring-indigo-600"
                    : "ring-1 ring-gray-200",
                  "rounded-3xl p-8 xl:p-10"
                )}
              >
                <div className="flex items-center justify-between gap-x-4">
                  <h3
                    id={tier.id}
                    className={classNames(
                      tier.mostPopular ? "text-indigo-600" : "text-gray-900",
                      "text-lg font-semibold leading-8"
                    )}
                  >
                    {tier.name}
                  </h3>
                  {tier.mostPopular ? (
                    <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">
                      Most popular
                    </p>
                  ) : null}
                </div>
                <p className="mt-4 text-sm leading-6 text-gray-600 md:line-clamp-1">
                  {tier.description}
                </p>
                {frequency.value === "company" && (
                  <p className="mt-4 text-sm leading-6 text-gray-600 italic">
                    {tier.subtitle}
                  </p>
                )}
                <p className="mt-6 flex items-center">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">
                    {tier.price[frequency.value]}
                  </span>
                  {tier.price[frequency.value] !== "Free" && (
                    <div className="inline-flex flex-col ml-2">
                      <span className="text-xs font-semibold leading-6 text-gray-600">
                        {frequency.priceSuffix}
                      </span>
                      <span className="text-xs font-semibold leading-6 text-gray-600">
                        billed annually
                      </span>
                    </div>
                  )}
                </p>
                <a
                  href={tier.href}
                  aria-describedby={tier.id}
                  className={classNames(
                    tier.mostPopular
                      ? "bg-indigo-600 text-white shadow-sm hover:bg-indigo-500"
                      : "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
                    "mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  )}
                >
                  Book Demo
                </a>
                <ul
                  role="list"
                  className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10"
                >
                  {featureToShow.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon
                        className="h-6 w-5 flex-none text-indigo-600"
                        aria-hidden="true"
                      />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
