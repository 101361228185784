import React, { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

const navigation = [
  { name: "Try Us", href: "#tryUs" },
  { name: "Book A Demo", href: "#bookADemo" },
  { name: "Product", href: "#product" },
  { name: "Our Prices", href: "#pricing" },
  { name: "Contact Us", href: "#contactus" },
  { name: "Home", href: "/" },
];

export default function Header({ showHome = false }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();

    if (mobileMenuOpen) {
      setMobileMenuOpen(false);
    }

    const href = e.target.getAttribute("href");
    const target = document.querySelector(href);

    if (target) {
      target.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <header className="absolute inset-x-0 top-0 z-50">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Knockpro</span>
            <img
              className="w-25 h-[33px] sm:w-auto sm:h-auto max-w-[200px] max-h-[66px]"
              src="/logo.png"
              alt=""
            />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => {
            if (!showHome && item.name === "Home") return null;
            if (showHome && item.name !== "Home") return null;
            return (
              <a
                key={item.name}
                href={item.href}
                onClick={showHome ? null : handleClick}
                className="text-sm font-semibold leading-6 text-gray-900 scroll-smooth"
              >
                {item.name}
              </a>
            );
          })}
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt=""
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => {
                  if (!showHome && item.name === "Home") return null;
                  if (showHome && item.name !== "Home") return null;
                  return (
                    <a
                      key={item.name}
                      href={item.href}
                      onClick={showHome ? null : handleClick}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
